import BaseModal from '@rsa-digital/evo-shared-components/components/Modal/SessionExpiryModal';
import { formatTimeInMinutesAndSeconds } from '@rsa-digital/evo-shared-components/helpers/timeHelpers';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processTextLink } from 'helpers/csTypeProcessors';
import { PageTitle, trackModalLinkClick } from 'helpers/eventTracking';
import { parseNumber } from 'helpers/integerHelpers';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { CsLink } from 'types/contentStack';

const DEFAULT_SESSION_TIMEOUT_IN_SECONDS = 5 * 60;

const sessionTimeoutInSeconds =
  parseNumber(process.env.GATSBY_SESSION_EXPIRY_MODAL_TIMEOUT_IN_SECONDS) ||
  DEFAULT_SESSION_TIMEOUT_IN_SECONDS;

type SessionExpiryModalProps = {
  onExpiry: () => void;
  onContinue: () => void;
  pageTitle: PageTitle;
  onEmailQuote?: () => void;
};

type CsSessionExpiryModal = {
  csPetGlobalConfig: {
    session_expiry_modal: {
      body_text: string;
      link: CsLink;
      heading: string;
      cta: {
        cta_screenreader_text: string;
        cta_text: string;
      };
      email_quote_cta: {
        email_quote_cta_screenreader_text: string;
        email_quote_cta_text: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetGlobalConfig {
      session_expiry_modal {
        body_text
        link {
          title
          href
        }
        heading
        cta {
          cta_screenreader_text
          cta_text
        }
        email_quote_cta {
          email_quote_cta_screenreader_text
          email_quote_cta_text
        }
      }
    }
  }
`;

const SessionExpiryModal: React.FC<SessionExpiryModalProps> = ({
  onExpiry,
  onContinue,
  onEmailQuote,
  pageTitle,
}) => {
  const modalContent = useStaticQuery<CsSessionExpiryModal>(query).csPetGlobalConfig
    .session_expiry_modal;

  const renderBody = (currentTimeRemaining: number): string => {
    const replaceTimeRemainingPlaceholder = replacePlaceholdersRichText(
      {
        timeRemaining: {
          getSubstitutionText: (timeRemaining) =>
            formatTimeInMinutesAndSeconds(timeRemaining),
          isPii: false,
        },
      },
      currentTimeRemaining
    );

    return replaceTimeRemainingPlaceholder(modalContent.body_text);
  };

  const link = processTextLink(modalContent.link);

  const linkWithTracking = link
    ? {
        ...link,
        onClick: () => trackModalLinkClick('session expiry modal', 'go to homepage'),
      }
    : undefined;

  return (
    <BaseModal
      data-cy="SessionExpiryModal"
      header={modalContent.heading}
      renderBody={renderBody}
      cta={{
        displayText: modalContent.cta.cta_text,
        screenReaderText: modalContent.cta.cta_screenreader_text,
        onClick: () => {
          onContinue();
          trackModalLinkClick(pageTitle, modalContent.cta.cta_text);
        },
      }}
      ctaSecondary={
        onEmailQuote
          ? {
              displayText: modalContent.email_quote_cta.email_quote_cta_text,
              screenReaderText:
                modalContent.email_quote_cta.email_quote_cta_screenreader_text,
              onClick: () => {
                onContinue();
                trackModalLinkClick(
                  'session expiry modal',
                  modalContent.email_quote_cta.email_quote_cta_text
                );
                onEmailQuote();
              },
            }
          : undefined
      }
      link={!onEmailQuote ? linkWithTracking : undefined}
      onExpiry={onExpiry}
      sessionTimeout={sessionTimeoutInSeconds}
    />
  );
};

export default SessionExpiryModal;
