import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import {
  initialLookupValue,
  LookupValue,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { FullAddress } from 'apiHelpers/address/address';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { initialDateValue } from './shared/dateValue';

export const UPDATE_CUSTOMER_DETAILS = 'UPDATE_CUSTOMER_DETAILS';

export type AddressInfo = { id?: string; description: string };

export type PostcodeLookup = LookupValue<string, AddressInfo[]>;

export type AddressDetails = {
  isManualAddress: boolean;
  usePreviousAddress: boolean;
  postcodeLookup: PostcodeLookup;
  address: FullAddress | undefined;
  flatNameOrNumber: string;
  houseNameOrNumber: string;
  street: string;
  town: string;
  county: string;
  postcode: string;
  village: string;
};

export type ContactDetails = {
  customerTelephone: string;
  customerEmail: string;
};

export type CustomerDetails = {
  customerFirstName: string;
  customerLastName: string;
  customerDob: DateValue;
  customerTitle: string;
  customerGender: string;
  numberOfPetsInHousehold: number | undefined;
} & ContactDetails &
  AddressDetails;

export const initialPostcodeLookup: PostcodeLookup = initialLookupValue('');

export const initialAddressDetails: AddressDetails = {
  isManualAddress: false,
  usePreviousAddress: false,
  postcodeLookup: initialPostcodeLookup,
  address: undefined,
  flatNameOrNumber: '',
  houseNameOrNumber: '',
  street: '',
  town: '',
  county: '',
  postcode: '',
  village: '',
};

export const initialCustomerDetails: CustomerDetails = {
  customerFirstName: '',
  customerLastName: '',
  customerTelephone: '',
  customerEmail: '',
  customerDob: initialDateValue,
  customerTitle: '',
  customerGender: '',
  numberOfPetsInHousehold: undefined,
  ...initialAddressDetails,
};

export type UpdateCustomerDetailsAction = {
  type: typeof UPDATE_CUSTOMER_DETAILS;
  update: CustomerDetails;
};

export const useCustomerDetails = (): [
  CustomerDetails,
  (update: Partial<CustomerDetails>) => void
] => {
  const customerDetails = useSelector((state: RootState) => state.customerDetails);
  const dispatch = useDispatch();

  const updateCustomerDetails = useCallback(
    (update: Partial<CustomerDetails>): void => {
      dispatch({ type: UPDATE_CUSTOMER_DETAILS, update });
    },
    [dispatch]
  );

  return [customerDetails, updateCustomerDetails];
};
